.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 15vh;
  padding: 1rem 2rem;
  box-sizing: border-box;
  background-color: #fff;
  color: #000;
  font-size: 3rem;
  font-weight: bold;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.275s ease-in-out;
}

.navSection {
  display: flex;
  gap: 1rem;
  /* Add some spacing between nav elements */
}

.header.top {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
}


.navElements {
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: .9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  padding: 5px;
  /* Smooth transition for hover effects */
}

.sayHelloButton {
  border: 1px solid #000;
  /* Add a border around the element */
 
  /* Optional: Add rounded corners */
}

.navElements:hover,
.sayHelloButton:hover {
  background-color: #f0f0f0;
  /* Optional: Add a background color on hover */
  color: #000;
  /* Optional: Change text color on hover */
}

.established {
  color: #000;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.establishedMobile {
  color: #000;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;
}

.redLogo {
  width: 13rem;
  height: auto;
}

.header.hidden {
  top: -100%;
}

.hamburger-menu {
  display: none;
  /* Hide the hamburger icon on desktop */
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.navSection.mobile {
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  padding-top: 1rem;
  width: 55%;
  min-height: 50%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.navSection.mobile.show {
  right: 0;
}

.navElements.mobile {
  margin: 1rem 0;
  font-size: 1.8rem;
}

@media (max-width: 1024px) {
  .header {
    height: 15vh;
    /* Keep the same height for mobile view */
    padding: 1rem 2rem;
    flex-direction: row;
    /* Align items in a row */
    align-items: center;
  }

  .established {
    display: none;
    /* Hide the established text on smaller screens */
  }

  .redLogo {
    width: 10rem;
    /* Adjust logo size for mobile */
  }

  .hamburger-menu {
    display: block;
    /* Show the hamburger icon on mobile */
  }

  .navSection {
    display: none;
    /* Hide the regular nav section on mobile */
  }

  .navSection.mobile.show {
    display: flex;
    /* Show the mobile nav section when active */
  }
}